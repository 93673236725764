




































import { Component, Vue, Watch } from 'vue-property-decorator'
import { vxm } from '@/store'

@Component
export default class SideBar extends Vue {
  private items = []
  private selectedCalendarId = null
  private initialized = false

  public created(): void {
    this.items = [
      { icon: 'fa-cogs', url: 'Planning', title: this.$t('c:booking:Planning') },
      { icon: 'fa-list-alt', url: 'Services', title: this.$t('c:booking:Services') },
      { icon: 'fa-list-ol', url: 'AddonServices', title: this.$t('c:booking:Addon services') },
      { icon: 'fa-ball-pile', url: 'ServiceCategories', title: this.$t('c:booking:Service categories') },
      { icon: 'fa-clock', url: 'Schedules', title: this.$t('c:booking:Schedule') },
      { icon: 'fa-toolbox', url: 'Resources', title: this.$t('c:booking:Resources') },
      { icon: 'fa-location', url: 'Places', title: this.$t('c:booking:Places') },
      { icon: 'fa-calendar', url: 'Calendars', title: this.$t('c:booking:Calendars') },
      { icon: 'fa-browser', url: 'Portals', title: this.$t('c:booking:Portals') },
      { icon: 'fa-envelope', url: 'Reminders', title: this.$t('c:booking:Reminders') },
    ]
    this.selectedCalendarId = this.routeCalendarId
    this.initialized = true
  }

  private get calendars() {
    return vxm.booking.calendars
  }

  private get routeCalendarId() {
    return parseInt(this.$route.params.calendarId)
  }

  private redirectToSelectedCalendar() {
    if (this.routeCalendarId === this.selectedCalendarId) {
      return
    }
    this.$router.push({
      name: this.$route.name,
      params: { calendarId: this.selectedCalendarId },
    })
  }

  @Watch('$route', { deep: true })
  private onRouteChange(route, oldRoute) {
    if (!this.initialized || route === oldRoute) {
      return
    }
    if (this.routeCalendarId && this.routeCalendarId !== this.selectedCalendarId) {
      this.selectedCalendarId = this.routeCalendarId
    }
  }

  @Watch('selectedCalendarId')
  private onSelectedCalendarIdChange() {
    this.redirectToSelectedCalendar()
  }

  private getUrl(part) {
    if (part === 'Portal') return { name: 'Booking/Portal', params: { calendarId: this.selectedCalendarId } } // tmp todo: should not be part of settings
    return { name: 'Settings/Booking/' + part, params: { calendarId: this.selectedCalendarId } }
  }
}
